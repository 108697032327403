@charset "utf-8";


body {
	padding: 20px 40px;
	font-family: 'Open Sans',sans-serif !important
}

h1, h2, h3, h4, h5, h6, p, a, li {
	font-size: 14px;
	font-weight: normal;
	color: #333;
	line-height: 150%;
}

h2 {
	font-size: 14px;
	margin-bottom: 0.5em;
	margin-top: 1.5em;
}

h3 {
	font-size: 14px;
	margin-bottom: 0.5em;
	margin-top: 1.5em;
}

hr {
	border: 0px;
	height: 1px;
	background-color: #000;
}

.container {
	max-width: 600px;
	margin: 20px auto;
}

nav {
	margin-bottom: 20px;
}

.nav_link {
	margin-right: 10px;
}

li {
	margin-bottom: 5px;
}

.title {
	font-size: 20px;
	text-align: center;
}

.title_small, .post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
	font-weight: bold;
}

.post-content img {
	max-width: 100%;
}

.text-center {
	text-align: center;
}

.pull-right {
	float: right;
}

.avoid-page-break {
	page-break-inside: avoid;
}

table {
	font-family: verdana,arial,sans-serif;
	font-size:11px;
	color:#333333;
	border-width: 1px;
	border-color: #666666;
	border-collapse: collapse;
}

table th {
	border-width: 1px;
	padding: 8px;
	border-style: solid;
	border-color: #666666;
	background-color: #dedede;
}

table td {
	border-width: 1px;
	padding: 8px;
	border-style: solid;
	border-color: #666666;
	background-color: #ffffff;
}

.highlight {
	border: 1px solid #999;
	padding: 0px 5px;
	overflow-x: scroll;
	margin-left: 0px;
	margin-right: 0px;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "syntax-highlighting";

@media print
{
	.no-print, .no-print *
	{
		display: none !important;
	}
}
